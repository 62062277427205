var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"quotes",staticClass:"quotes",attrs:{"fluid":""}},[(_vm.isViewingQuote)?_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"quote-card"},[_c('v-card-title',{staticClass:"quote-card-title pb-0"},[_c('AppButton',{attrs:{"variation":"ghost"},on:{"click":_vm.viewList}},[_c('i',{staticClass:"fal fa-chevron-left pr-1"}),_vm._v("All Quotes ")])],1),_c('v-card-text',[_c('Quote',{attrs:{"quote":_vm.quote,"disabled":_vm.quoteDisabled},on:{"saved":_vm.handleSaved}})],1)],1)],1)],1)],1):_c('div',[(_vm.notApproved)?_c('v-row',[_c('v-col',[_c('Alert',{attrs:{"type":"info","full-width":"","persistent":""}},[_vm._v(" "+_vm._s(_vm.$t('quotes.notApproved'))+" ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-left"},[_c('AppButton',{staticClass:"mt-1 mb-3",attrs:{"disabled":!_vm.selected.length || _vm.requestQuotesDisabled,"loading":_vm.requestingQuotes},on:{"click":function($event){_vm.showQuotesDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('quotes.requestButton', [_vm.selected.length]))+" ")]),_c('ResponseDateDialog',{attrs:{"show":_vm.showQuotesDialog,"title":_vm.$t('quotes.requestButton', [_vm.selected.length])},on:{"responseDateDialogClosed":function($event){_vm.showQuotesDialog = false},"responseDateSelected":_vm.requestQuotes}})],1),_c('div',[_c('AppButton',{staticClass:"mt-1 mb-3",attrs:{"variation":"ghost","disabled":_vm.selectMarketDisabled},on:{"click":function($event){_vm.showMarketSelect = true}}},[_vm._v(" "+_vm._s(_vm.$t('quotes.editMarketSelection'))+" ")])],1)]),_c('v-data-table',{staticClass:"quotes-list-table",attrs:{"loading":_vm.loading,"loading-text":_vm.$t('quotes.listLoading'),"headers":_vm.tableHeaders,"items":_vm.list,"item-key":"id","show-select":!_vm.disabled,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"color":"aon-teal","ripple":false,"disabled":_vm.requestQuotesDisabled}},'v-simple-checkbox',props,false),on))]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"aon-teal","ripple":false,"value":isSelected,"disabled":_vm.requestQuotesDisabled},on:{"input":function($event){return select($event)}}})]}},{key:"item.market",fn:function(ref){
var item = ref.item;
var header = ref.header;
var value = ref.value;
return [_c('AppButton',{attrs:{"variation":"ghost","disabled":_vm.disabled},on:{"click":function($event){return _vm.viewQuote(item.id)}}},[_vm._v(" "+_vm._s(value)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.error)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('quotes.listError'))+" ")])],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showMarketSelect),callback:function ($$v) {_vm.showMarketSelect=$$v},expression:"showMarketSelect"}},[_c('MarketSelection',{attrs:{"selected":_vm.currentMarkets,"disabled":_vm.marketSelectDisabled}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }