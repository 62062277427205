<template>
  <v-container ref="quotes" class="quotes" fluid>
    <div v-if="isViewingQuote">
      <v-row>
        <v-col>
          <v-card class="quote-card">
            <v-card-title class="quote-card-title pb-0">
              <AppButton variation="ghost" @click="viewList">
                <i class="fal fa-chevron-left pr-1"></i>All Quotes
              </AppButton>
            </v-card-title>
            <v-card-text>
              <Quote
                :quote="quote"
                :disabled="quoteDisabled"
                @saved="handleSaved"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row v-if="notApproved">
        <v-col>
          <Alert type="info" full-width persistent>
            {{ $t('quotes.notApproved') }}
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <AppButton
                class="mt-1 mb-3"
                :disabled="!selected.length || requestQuotesDisabled"
                :loading="requestingQuotes"
                @click="showQuotesDialog = true"
              >
                {{ $t('quotes.requestButton', [selected.length]) }}
              </AppButton>
              <ResponseDateDialog
                :show="showQuotesDialog"
                :title="$t('quotes.requestButton', [selected.length])"
                @responseDateDialogClosed="showQuotesDialog = false"
                @responseDateSelected="requestQuotes"
              >
              </ResponseDateDialog>
            </div>
            <div>
              <AppButton
                variation="ghost"
                class="mt-1 mb-3"
                :disabled="selectMarketDisabled"
                @click="showMarketSelect = true"
              >
                {{ $t('quotes.editMarketSelection') }}
              </AppButton>
            </div>
          </div>
          <v-data-table
            v-model="selected"
            class="quotes-list-table"
            :loading="loading"
            :loading-text="$t('quotes.listLoading')"
            :headers="tableHeaders"
            :items="list"
            item-key="id"
            :show-select="!disabled"
            hide-default-footer
          >
            <template #header.data-table-select="{ on, props }">
              <v-simple-checkbox
                color="aon-teal"
                v-bind="props"
                :ripple="false"
                :disabled="requestQuotesDisabled"
                v-on="on"
              />
            </template>
            <template #item.data-table-select="{ isSelected, select }">
              <v-simple-checkbox
                color="aon-teal"
                :ripple="false"
                :value="isSelected"
                :disabled="requestQuotesDisabled"
                @input="select($event)"
              />
            </template>
            <template #item.market="{ item, header, value }">
              <AppButton
                variation="ghost"
                :disabled="disabled"
                @click="viewQuote(item.id)"
              >
                {{ value }}
              </AppButton>
            </template>
          </v-data-table>
          <v-row v-if="error">
            <v-col>
              <v-alert type="error">
                {{ $t('quotes.listError') }}
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showMarketSelect" width="500">
      <MarketSelection
        :selected="currentMarkets"
        :disabled="marketSelectDisabled"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import INSURANCE_APPLICATION_STATUSES from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';
import QUOTE_STATUSES from '@/lib/constants/quoteStatuses';
import { mapState } from 'vuex';
import Quote from './Quote';
import MarketSelection from '@/components/CoverageRequests/Quotes/MarketSelection';
import ResponseDateDialog from '@/components/CoverageRequests/ResponseDateDialog';

export default {
  name: 'Quotes',
  components: {
    MarketSelection,
    Quote,
    ResponseDateDialog,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    insuranceType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isViewingQuote: false,
      quote: null,
      loading: false,
      requestingQuotes: false,
      selected: [],
      showMarketSelect: false,
      showQuotesDialog: false,
      tableHeaders: [
        {
          text: this.$t('quotes.listHeaders.market'),
          value: 'market',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.premium'),
          value: 'premium',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.coverage'),
          value: 'coverageAmount',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.perOccurrenceCoverage'),
          value: 'perOccurrenceCoverageAmount',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.deductible'),
          value: 'deductible',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.territory'),
          value: 'territory',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.policyType'),
          value: 'policyType',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.extReporting'),
          value: 'extendedReportingPeriod',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('quotes.listHeaders.status'),
          value: 'status',
          filterable: false,
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('quotes', {
      quotes: state => state.quotes,
    }),
    ...mapState('insuranceApplication', {
      applicationStatus: state => state.application.status,
    }),
    selectMarketDisabled() {
      const acceptableStatuses = [
        INSURANCE_APPLICATION_STATUSES.APPROVED,
        INSURANCE_APPLICATION_STATUSES.OUT_TO_MARKET,
        INSURANCE_APPLICATION_STATUSES.COMPLETED,
      ];
      return (
        this.loading || !acceptableStatuses.includes(this.applicationStatus)
      );
    },
    list() {
      let currency = this.$options.filters.currency;
      return this.loading
        ? []
        : this.quotes.map(x => {
            return {
              id: x.id,
              market: x.market,
              premium: x.premium ? currency(x.premium) : '--',
              coverageAmount: x.coverageAmount
                ? currency(x.coverageAmount)
                : '--',
              perOccurrenceCoverageAmount: x.perOccurrenceCoverageAmount
                ? currency(x.perOccurrenceCoverageAmount)
                : '--',
              deductible: x.deductible ? currency(x.deductible) : '--',
              territory: x.territory ? x.territory : '--',
              policyType: x.policyType ? x.policyType : '--',
              extendedReportingPeriod: x.extendedReportingPeriod
                ? `${x.extendedReportingPeriod} ${this.$t(
                    'quotes.extendedReportingUnit'
                  )}`
                : '--',
              status: this.formatStatus(x),
            };
          });
    },
    requestQuotesDisabled() {
      return (
        this.loading ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.SUBMITTED ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.FINALIZE ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.COMPLETED
      );
    },
    notApproved() {
      return (
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.SUBMITTED
      );
    },
    currentMarkets() {
      return this.quotes.map(x => ({
        marketId: x.marketId,
        marketContactId: x.marketContactId,
      }));
    },
    quoteDisabled() {
      return (
        this.applicationStatus ===
          INSURANCE_APPLICATION_STATUSES.APPLICANT_REVIEW ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.FINALIZE ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.COMPLETED ||
        this.quote.status === QUOTE_STATUSES.PROPOSED ||
        this.quote.status === QUOTE_STATUSES.ACCEPTED ||
        this.quote.status === QUOTE_STATUSES.DECLINED
      );
    },
    marketSelectDisabled() {
      return (
        this.applicationStatus ===
          INSURANCE_APPLICATION_STATUSES.APPLICANT_REVIEW ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.FINALIZE ||
        this.applicationStatus === INSURANCE_APPLICATION_STATUSES.COMPLETED
      );
    },
  },
  mounted() {
    this.fetchQuotes();
  },
  beforeCreate() {
    this.$AppEventBus.$on('closeMarketSelectDialog', (refresh = false) => {
      this.showMarketSelect = false;
      if (refresh) {
        this.fetchQuotes();
      }
    });
  },
  methods: {
    fetchQuotes() {
      const applicationId = this.$route.params.id;
      if (applicationId) {
        this.loading = true;
        this.$store.dispatch('quotes/getQuotes', applicationId).finally(() => {
          this.loading = false;
        });
      }
    },
    formatStatus(item) {
      let t = this.$t(`quotes.listData.${item.status}`);

      switch (item.status) {
        case QUOTE_STATUSES.DRAFT:
          return t;
        case QUOTE_STATUSES.REQUESTED:
          return `${t} ${this.$options.filters.longDate(item.requested)}`;
        case QUOTE_STATUSES.COMPLETED:
          return `${t} ${this.$options.filters.longDate(item.completed)}`;
        case QUOTE_STATUSES.PROPOSED:
          return `${t} ${this.$options.filters.longDate(item.proposed)}`;
        case QUOTE_STATUSES.ACCEPTED:
          return `${t} ${this.$options.filters.longDate(item.accepted)}`;
        case QUOTE_STATUSES.DECLINED:
          return `${t} ${this.$options.filters.longDate(item.declined)}`;
        case QUOTE_STATUSES.FINALIZED:
          return `${t} ${this.$options.filters.longDate(item.finalized)}`;
        default:
          return this.$t('quotes.listData.UNKNOWN');
      }
    },
    viewList() {
      this.isViewingQuote = false;
      this.fetchQuotes();
    },
    handleSaved() {
      this.viewList();
    },
    viewQuote(quoteId) {
      let quote = this.quotes.find(x => x.id === quoteId);
      this.quote = { ...quote };
      this.isViewingQuote = true;
    },
    async requestQuotes(responseDate) {
      this.loading = true;
      this.requestingQuotes = true;
      let quoteIds = this.selected.map(x => x.id);

      const payload = {
        coverageAmount: this.application.requestedCoverage.coverageAmount,
        companyName: this.application.businessInformation.legalName,
        insuranceType: this.insuranceType,
        quoteIds,
        responseDate,
      };

      try {
        await this.$store.dispatch('quotes/requestQuotes', payload).then(() => {
          this.$AppEventBus.$emit('snacktime', {
            type: 'success',
            message: this.$t('quotes.request.success'),
            timeout: 5000,
          });
          this.selected = [];
          this.fetchQuotes();
          this.showQuotesDialog = false;
        });

        if (
          this.application.status !==
          INSURANCE_APPLICATION_STATUSES.OUT_TO_MARKET
        ) {
          await this.$store.dispatch(
            'insuranceApplication/updateStatus',
            INSURANCE_APPLICATION_STATUSES.OUT_TO_MARKET
          );
        }
      } catch (error) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('quotes.request.error'),
          persistent: true,
        });
      }

      this.requestingQuotes = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.aon.v-application {
  .quotes {
    .quotes-list-table {
      border: 1px solid $aon-gray-02;

      .v-data-table-header {
        background: $white;

        th {
          &:not(:last-of-type) {
            border-right: 1px solid $aon-gray-02;
          }
        }
      }

      .market-select {
        text-decoration: none;
      }

      .market-select:before {
        display: none;
      }
    }

    .quote-card {
      .quote-card-title {
        .all-quotes {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
