import { translator } from '@aon/cfs-utils/lib/translation';
import { Model, VueForm } from '@aon/cfs-forms';
import { maxValue, minValue, maxLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import { fileType, maxFileSize } from '@aon/cfs-forms/lib/validators';

const base = (validations = {}, defaultValue = null) => ({
  default: defaultValue,
  validations: validations,
});

const maxDollarAmount = 1000000000;
const maxWaitingPeriod = 999;

const model = new Model({
  id: base(),
  referenceNumber: base({
    maxLength: maxLength(100),
  }),
  coverageEffective: base(),
  coverageExpiration: base({
    greaterThanToday: value => {
      const today = moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      const expirationDate = moment(value).toDate();
      return minValue(today)(expirationDate);
    },
    greaterThanEffectiveDate: (value, fields) => {
      const effectiveDate = moment(fields.effectiveDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      const expirationDate = moment(value).toDate();
      return minValue(effectiveDate)(expirationDate);
    },
  }),
  extendedReportingPeriod: base({
    minValue: minValue(0),
  }),
  coverageAmount: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  perOccurrenceCoverageAmount: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  deductible: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  territory: base({
    maxLength: maxLength(3),
  }),
  policyType: base(),
  premium: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  terms: base({
    maxLength: maxLength(255),
  }),
  additionalDetails: base({
    maxLength: maxLength(1000),
  }),
  admitted: base({
    maxLength: maxLength(255),
  }),
  aggregateLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  retention: base({
    maxLength: maxLength(255),
  }),
  brokerageFee: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  surplusLinesTaxesFees: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  totalQuoteCost: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  technologyEao: base({
    maxLength: maxLength(255),
  }),
  miscellaneousProfessionalLiability: base({
    maxLength: maxLength(255),
  }),
  mediaLiabilityCoverageLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  securityLiabilityLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  privacyLiabilityLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  regulatoryProceedingsLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  paymentCardDataSecurityStandardsLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  breachEventExpensesLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  businessInterruptionLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  businessInterruptionHourlyWaitingPeriod: base({
    minValue: minValue(0),
    maxValue: maxValue(maxWaitingPeriod),
  }),
  dependentBusinessInterruptionLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  dependentBusinessInterruptionHourlyWaitingPeriod: base({
    minValue: minValue(0),
    maxValue: maxValue(maxWaitingPeriod),
  }),
  systemFailureBusinessInterruptionLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  systemFailureBusinessInterruptionHourlyWaitingPeriod: base({
    minValue: minValue(0),
    maxValue: maxValue(maxWaitingPeriod),
  }),
  dependentSystemFailureLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  dependentSystemFailureHourlyWaitingPeriod: base({
    minValue: minValue(0),
    maxValue: maxValue(maxWaitingPeriod),
  }),
  digitalAssetProtectionLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  cyberExtortionLimit: base({
    minValue: minValue(0),
    maxValue: maxValue(maxDollarAmount),
  }),
  commission: base({
    minValue: minValue(0),
    maxValue: maxValue(100),
  }),
  retroactiveDate: base(),
  file: {
    default: null,
    validations: { maxFileSize: maxFileSize(), fileType: fileType() },
  },
});

export const extendedReportingOptions = new Array(6).fill().map((_, index) => ({
  value: index + 1,
  text: translator.$tc('quotes.quote.years', [index + 1]),
}));

export default function QuoteForm(initialValues) {
  return VueForm({
    model,
    initialValues,
    customErrorMessages: {
      greaterThanToday: translator.$t('quotes.quote.greaterThanToday'),
      greaterThanEffectiveDate: translator.$t(
        'quotes.quote.greaterThanEffectiveDate'
      ),
    },
    data: { extendedReportingOptions },
    methods: {
      getFormValues() {
        const values = this._getFormValues();

        values.effectiveDate = moment.utc(values.effectiveDate).format();
        values.expirationDate = moment.utc(values.expirationDate).format();

        return values;
      },
    },
  });
}
