<template>
  <v-form>
    <v-container class="quote py-0" fluid>
      <v-row>
        <v-col>
          <div class="subtitle-1 aon-teal--text">
            {{ $t('quotes.quote.contact') }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" md="6" sm="12">
          <div class="subtitle-1 aon-gray-01--text">
            {{ quote.marketContact.firstName }}
            {{ quote.marketContact.lastName }}
          </div>
          <div class="body-2 aon-gray-01--text">
            {{ quote.marketContact.email }}
          </div>
          <div class="body-2 aon-gray-01--text">
            {{ contactAddress }}
          </div>
        </v-col>
        <v-col lg="3" md="3" sm="12"> </v-col>
        <v-col lg="3" md="3" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.referenceNumber')"
            :required="null"
          >
            <v-text-field
              v-model="$v.fields.referenceNumber.$model"
              :error-messages="form.errors($v.fields.referenceNumber)"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="subtitle-1 aon-teal--text">
            {{ $t('quotes.quote.details') }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.coverageEffective')"
            :required="null"
          >
            <DatePicker
              :date.sync="coverageEffective"
              :error-messages="form.errors($v.fields.coverageEffective)"
              outlined
              label=""
              dense
              :disabled="disabled"
            />
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.coverageExpiration')"
            :required="null"
          >
            <DatePicker
              :date.sync="coverageExpiration"
              :error-messages="form.errors($v.fields.coverageExpiration)"
              outlined
              label=""
              dense
              :disabled="disabled"
            />
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.extendedReportingPeriod')"
            :required="null"
          >
            <v-select
              v-model="$v.fields.extendedReportingPeriod.$model"
              :disabled="disabled"
              :error-messages="form.errors($v.fields.extendedReportingPeriod)"
              :items="form.extendedReportingOptions"
              outlined
              dense
              @blur="$v.fields.extendedReportingPeriod.$touch()"
            />
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.coverageAmount')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.coverageAmount.$model"
              :error-messages="form.errors($v.fields.coverageAmount)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.perOccurrenceCoverageAmount')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.perOccurrenceCoverageAmount.$model"
              :error-messages="
                form.errors($v.fields.perOccurrenceCoverageAmount)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.deductible')" :required="null">
            <v-text-field
              v-model.number="$v.fields.deductible.$model"
              :error-messages="form.errors($v.fields.deductible)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="3" md="6" sm="12">
          <CountrySelect
            v-model="$v.fields.territory.$model"
            :label="$t('insuranceApplications.requestedCoverage.territory')"
            :error-messages="form.errors($v.fields.territory)"
            :disabled="disabled"
            :required="true"
          />
        </v-col>
        <v-col lg="3" md="6" sm="12">
          <LabeledInput
            :label="$t('insuranceApplications.requestedCoverage.policyType')"
            :required="true"
          >
            <v-select
              v-model="$v.fields.policyType.$model"
              :error-messages="form.errors($v.fields.policyType)"
              :items="policyTypeOptions"
              :disabled="disabled"
              outlined
              dense
              @blur="$v.fields.policyType.$touch()"
            />
          </LabeledInput>
        </v-col>
        <v-col lg="3" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.premium')" :required="null">
            <v-text-field
              v-model.number="$v.fields.premium.$model"
              :error-messages="form.errors($v.fields.premium)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="3" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.terms')" :required="null">
            <v-text-field
              v-model="$v.fields.terms.$model"
              :error-messages="form.errors($v.fields.terms)"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <LabeledInput
            :label="$t('quotes.quote.additionalDetails')"
            :required="null"
          >
            <v-textarea
              v-model="$v.fields.additionalDetails.$model"
              :error-messages="form.errors($v.fields.additionalDetails)"
              outlined
              dense
              rows="3"
              :disabled="disabled"
            />
          </LabeledInput>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="10">
          <Spinner v-if="loadingFile" :width="20" />
          <template v-else>
            <template>
              <LabeledInput
                :label="$t('quotes.quoteDocument')"
                :required="null"
              />
              <v-row>
                <AppButton
                  v-if="disabled"
                  variation="ghost"
                  class="mt-2"
                  :disabled="!quote.documentFileName"
                  @click="downloadQuoteDocument()"
                >
                  <strong>
                    {{ quote.documentFileName || $t('quotes.noDocument') }}
                  </strong>
                </AppButton>
              </v-row>
              <div class="file-upload mb-2">
                <v-file-input
                  v-if="!disabled"
                  v-model="$v.fields.file.$model"
                  :placeholder="$t('form.uploadFile')"
                  :error-messages="form.errors($v.fields.file)"
                  accept="application/pdf"
                  solo
                  flat
                  filled
                  background-color="aon-gray-08"
                  prepend-icon=""
                  prepend-inner-icon="fal fa-paperclip"
                />
              </div>
            </template>
          </template>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg="5" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.admitted')" :required="null">
            <v-text-field
              v-model="$v.fields.admitted.$model"
              :error-messages="form.errors($v.fields.admitted)"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.aggregateLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.aggregateLimit.$model"
              :error-messages="form.errors($v.fields.aggregateLimit)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput :label="$t('quotes.quote.retention')" :required="null">
            <v-text-field
              v-model="$v.fields.retention.$model"
              :error-messages="form.errors($v.fields.retention)"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.brokerageFee')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.brokerageFee.$model"
              :error-messages="form.errors($v.fields.brokerageFee)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.surplusLinesTaxesFees')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.surplusLinesTaxesFees.$model"
              :error-messages="form.errors($v.fields.surplusLinesTaxesFees)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.totalQuoteCost')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.totalQuoteCost.$model"
              :error-messages="form.errors($v.fields.totalQuoteCost)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.technologyEao')"
            :required="null"
          >
            <v-text-field
              v-model="$v.fields.technologyEao.$model"
              :error-messages="form.errors($v.fields.technologyEao)"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.miscellaneousProfessionalLiability')"
            :required="null"
          >
            <v-text-field
              v-model="$v.fields.miscellaneousProfessionalLiability.$model"
              :error-messages="
                form.errors($v.fields.miscellaneousProfessionalLiability)
              "
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.mediaLiabilityCoverageLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.mediaLiabilityCoverageLimit.$model"
              :error-messages="
                form.errors($v.fields.mediaLiabilityCoverageLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.securityLiabilityLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.securityLiabilityLimit.$model"
              :error-messages="form.errors($v.fields.securityLiabilityLimit)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.privacyLiabilityLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.privacyLiabilityLimit.$model"
              :error-messages="form.errors($v.fields.privacyLiabilityLimit)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.regulatoryProceedingsLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.regulatoryProceedingsLimit.$model"
              :error-messages="
                form.errors($v.fields.regulatoryProceedingsLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.paymentCardDataSecurityStandardsLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.paymentCardDataSecurityStandardsLimit.$model
              "
              :error-messages="
                form.errors($v.fields.paymentCardDataSecurityStandardsLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="5" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.breachEventExpensesLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.breachEventExpensesLimit.$model"
              :error-messages="form.errors($v.fields.breachEventExpensesLimit)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.businessInterruptionLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.businessInterruptionLimit.$model"
              :error-messages="form.errors($v.fields.businessInterruptionLimit)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.businessInterruptionHourlyWaitingPeriod')"
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.businessInterruptionHourlyWaitingPeriod.$model
              "
              :error-messages="
                form.errors($v.fields.businessInterruptionHourlyWaitingPeriod)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.dependentBusinessInterruptionLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.dependentBusinessInterruptionLimit.$model
              "
              :error-messages="
                form.errors($v.fields.dependentBusinessInterruptionLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="
              $t(
                'quotes.quote.dependentBusinessInterruptionHourlyWaitingPeriod'
              )
            "
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.dependentBusinessInterruptionHourlyWaitingPeriod
                  .$model
              "
              :error-messages="
                form.errors(
                  $v.fields.dependentBusinessInterruptionHourlyWaitingPeriod
                )
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.systemFailureBusinessInterruptionLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.systemFailureBusinessInterruptionLimit.$model
              "
              :error-messages="
                form.errors($v.fields.systemFailureBusinessInterruptionLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="
              $t(
                'quotes.quote.systemFailureBusinessInterruptionHourlyWaitingPeriod'
              )
            "
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.systemFailureBusinessInterruptionHourlyWaitingPeriod
                  .$model
              "
              :error-messages="
                form.errors(
                  $v.fields.systemFailureBusinessInterruptionHourlyWaitingPeriod
                )
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.dependentSystemFailureLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.dependentSystemFailureLimit.$model"
              :error-messages="
                form.errors($v.fields.dependentSystemFailureLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="
              $t('quotes.quote.dependentSystemFailureHourlyWaitingPeriod')
            "
            :required="null"
          >
            <v-text-field
              v-model.number="
                $v.fields.dependentSystemFailureHourlyWaitingPeriod.$model
              "
              :error-messages="
                form.errors($v.fields.dependentSystemFailureHourlyWaitingPeriod)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            />
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.digitalAssetProtectionLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.digitalAssetProtectionLimit.$model"
              :error-messages="
                form.errors($v.fields.digitalAssetProtectionLimit)
              "
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <LabeledInput
            :label="$t('quotes.quote.cyberExtortionLimit')"
            :required="null"
          >
            <v-text-field
              v-model.number="$v.fields.cyberExtortionLimit.$model"
              :error-messages="form.errors($v.fields.cyberExtortionLimit)"
              type="number"
              class="hide-number-spinner"
              outlined
              dense
              :disabled="disabled"
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
          <v-row dense>
            <v-col class="py-0" lg="5" sm="6">
              <LabeledInput
                :label="$t('quotes.quote.commission')"
                :required="null"
              >
                <v-text-field
                  v-model.number="$v.fields.commission.$model"
                  :error-messages="form.errors($v.fields.commission)"
                  type="number"
                  class="hide-number-spinner"
                  outlined
                  dense
                  :disabled="disabled"
                >
                  <template #append>
                    <v-icon class="text-field-icon" color="aon-gray-02" small>
                      fal fa-percent
                    </v-icon>
                  </template>
                </v-text-field>
              </LabeledInput>
            </v-col>
          </v-row>
          <LabeledInput
            :label="$t('quotes.quote.retroactiveDate')"
            :required="null"
          >
            <DatePicker
              :date.sync="retroactiveDate"
              outlined
              label=""
              dense
              :disabled="disabled"
            />
          </LabeledInput>
        </v-col>
      </v-row>
      <v-row v-if="!disabled" dense>
        <v-col>
          <div class="text-right">
            <AppButton :loading="saving" @click="saveQuote">
              {{ $t('quotes.quote.save') }}
            </AppButton>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import CountrySelect from '@aon/cfs-components/src/components/Forms/CountrySelect';
import QuoteForm from '@/lib/forms/quote';
import {
  getQuoteDocument,
  uploadQuoteDocument,
  removeQuoteDocument,
} from '../../../api/quotes';

export default {
  name: 'Quote',
  components: {
    DatePicker,
    LabeledInput,
    CountrySelect,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: QuoteForm(this.quote),
      saving: false,
      loadingFile: false,
    };
  },
  computed: {
    contactAddress() {
      let contact = this.quote.marketContact;
      return `${contact.addressLine1} ${contact.locality}, ${contact.region} ${contact.postalCode}`;
    },
    fields() {
      return this.form.fields;
    },
    $v() {
      return this.form.$v;
    },
    coverageEffective: {
      get() {
        return this.$v.fields.coverageEffective.$model
          ? this.$moment(this.$v.fields.coverageEffective.$model).format(
              'YYYY-MM-DD'
            )
          : null;
      },
      set(value) {
        this.$v.fields.coverageEffective.$model = value
          ? this.$moment(value).format()
          : null;
      },
    },
    coverageExpiration: {
      get() {
        return this.$v.fields.coverageExpiration.$model
          ? this.$moment(this.$v.fields.coverageExpiration.$model).format(
              'YYYY-MM-DD'
            )
          : null;
      },
      set(value) {
        this.$v.fields.coverageExpiration.$model = value
          ? this.$moment(value).format()
          : null;
      },
    },
    retroactiveDate: {
      get() {
        return this.$v.fields.retroactiveDate.$model
          ? this.$moment(this.$v.fields.retroactiveDate.$model).format(
              'YYYY-MM-DD'
            )
          : null;
      },
      set(value) {
        this.$v.fields.retroactiveDate.$model = value
          ? this.$moment(value).format()
          : null;
      },
    },
    policyTypeOptions() {
      return Object.entries(
        this.$t('insuranceApplications.requestedCoverage.policyTypeOptions')
      ).map(([value, text]) => ({
        text,
        value,
      }));
    },
  },
  created() {
    if (!!this.quote.documentFileName) {
      this.$v.fields.file.$model = new File([], this.quote.documentFileName, {
        type: 'application/pdf',
      });
    }
  },
  methods: {
    async saveQuote() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.saving = true;
      this.error = null;
      try {
        if (!this.$v.fields.file.$model && !!this.quote.documentFileName) {
          await removeQuoteDocument(this.quote.id);
        } else {
          await this.uploadQuoteDocument();
        }
        const quote = this.deleteExtraFields(this.form.getFormValues());
        await this.$store.dispatch('quotes/updateQuote', quote);
        this.$AppEventBus.$emit('snacktime', {
          type: 'success',
          message: this.$t('quotes.quote.success'),
          timeout: 5000,
        });
        this.saving = false;
        this.$emit('saved');
      } catch (error) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('global.genericErrorMsg'),
        });
        this.saving = false;
      }
    },
    async uploadQuoteDocument() {
      if (this.$v.fields.file.$model && this.$v.fields.file.$model.size) {
        try {
          await uploadQuoteDocument({
            quoteId: this.quote.id,
            file: this.$v.fields.file.$model,
            tenantId: this.$route.params.tenantId,
          });
        } catch (e) {
          this.$AppEventBus.$emit('snacktime', {
            type: 'error',
            message: this.$t('global.genericErrorMsg'),
          });
        }
      }
    },
    async downloadQuoteDocument() {
      if (!this.disabled || !this.quote.documentFileName) {
        return;
      }
      this.loadingFile = true;

      try {
        const { data } = await getQuoteDocument(this.quote.id);
        window.open(data, '_self');
      } catch (error) {
        this.$logger.error(error);
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('quotes.documentLoadError'),
        });
      }

      this.loadingFile = false;
    },
    deleteExtraFields(quote) {
      delete quote['applicationId'];
      delete quote['market'];
      delete quote['marketContact'];
      delete quote['status'];
      delete quote['requested'];
      delete quote['completed'];
      delete quote['proposed'];
      delete quote['accepted'];
      delete quote['declined'];
      delete quote['finalized'];
      delete quote['created'];
      delete quote['updated'];
      delete quote['deleted'];
      delete quote['file'];
      return quote;
    },
  },
};
</script>

<style lang="scss">
.aon.v-application {
  .quotes {
    .quote {
      .text-field-icon {
        padding-top: 2px;
      }
    }
    div.file-upload {
      min-width: 750px;

      .v-btn__content {
        width: 104%;
      }
      .v-icon.far.fa-paperclip {
        font-size: 28px;
        margin-left: 10px;
      }

      .v-input--is-dirty {
        .v-icon.far.fa-paperclip {
          color: $aon-teal;
        }
      }

      .v-file-input__text {
        color: $aon-teal;
        font-weight: 500;
      }

      .v-text-field__slot {
        margin-left: 14px;
        color: $aon-teal;
        font-weight: 500;
        min-height: 4em;
      }
    }
  }
}
</style>
