<template>
  <v-dialog
    v-model="showDialog"
    content-class="response-date-dialog"
    width="400"
    @click:outside="closeDialog"
  >
    <v-card>
      <v-card-title>
        {{ cardTitle }}
      </v-card-title>
      <v-card-text class="pt-3">
        <LabeledInput :label="responseDateLabel" :required="true">
          <DatePicker
            :date.sync="selectedResponseDate"
            label=""
            dense
            :error-messages="responseDateErrorMsg"
          />
        </LabeledInput>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <AppButton class="my-2" variation="ghost" @click="closeDialog">
          {{ $t('forms.cancel') }}
        </AppButton>
        <AppButton
          class="ma-2"
          :disabled="!!responseDateErrorMsg"
          @click="responseDateSelected"
        >
          {{ $t('forms.save') }}
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
export default {
  name: 'ResponseDialog',
  components: {
    DatePicker,
    LabeledInput,
  },
  props: {
    defaultResponseDate: {
      type: String,
      default: moment()
        .add(3, 'days')
        .format('YYYY-MM-DD'),
    },
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      responseDate: this.defaultResponseDate,
      responseDateErrorMsg: null,
      showDialog: this.show,
    };
  },
  computed: {
    cardTitle() {
      return this.title;
    },
    responseDateLabel() {
      return this.label || this.$t('coverageRequest.responseDate');
    },
    selectedResponseDate: {
      get() {
        return this.responseDate;
      },
      set(date) {
        if (moment(date).isAfter()) {
          this.responseDateErrorMsg = null;
          this.responseDate = date;

          return;
        }

        this.responseDateErrorMsg = this.$t('coverageRequest.futureDateError');
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.showDialog = true;
      }
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit('responseDateDialogClosed');
    },
    responseDateSelected() {
      this.$emit('responseDateSelected', this.responseDate);
      this.closeDialog();
    },
  },
};
</script>
