<template>
  <v-dialog
    v-model="open"
    class="identification-information"
    max-width="30rem"
    persistent
  >
    <template #activator="{ on }">
      <AppButton variation="ghost" v-on="on">
        {{ $t('identificationInformation.edit') }}
      </AppButton>
    </template>
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h4 class="aon-peacock--text mb-0">
          {{ $t('identificationInformation.title') }}
        </h4>
        <AppButton variation="icon" :disabled="loading" @click="close">
          <i class="fal fa-times" />
        </AppButton>
      </v-card-title>
      <v-card-text>
        <LabeledInput :label="$t('identificationInformation.agencyCustomerId')">
          <v-text-field
            v-model="$v.fields.agencyCustomerId.$model"
            :disabled="loading"
            :error-messages="form.errors($v.fields.agencyCustomerId)"
            dense
            outlined
          />
        </LabeledInput>
        <LabeledInput :label="$t('identificationInformation.agency')">
          <v-text-field
            v-model="$v.fields.agency.$model"
            :disabled="loading"
            :error-messages="form.errors($v.fields.agency)"
            dense
            outlined
          />
        </LabeledInput>
        <LabeledInput :label="$t('identificationInformation.policyNumber')">
          <v-text-field
            v-model="$v.fields.policyNumber.$model"
            :disabled="loading"
            :error-messages="form.errors($v.fields.policyNumber)"
            dense
            outlined
          />
        </LabeledInput>
        <LabeledInput :label="$t('identificationInformation.naicCode')">
          <v-text-field
            v-model="$v.fields.naicCode.$model"
            :disabled="loading"
            :error-messages="form.errors($v.fields.naicCode)"
            dense
            outlined
          />
        </LabeledInput>
        <div class="d-flex justify-end">
          <AppButton
            class="mr-2"
            :disabled="loading"
            variation="ghost"
            @click="close"
          >
            {{ $t('identificationInformation.cancel') }}
          </AppButton>
          <AppButton :loading="loading" @click="save">
            {{ $t('identificationInformation.save') }}
          </AppButton>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import IdentificationInformationForm from '@/lib/forms/identification-information';
import checkValidations from '@aon/cfs-components/src/mixins/check-validation';
import hasFormValidations from '@aon/cfs-components/src/mixins/has-form-validations';

import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';

export default {
  name: 'IdentificationInformationDialog',
  components: {
    LabeledInput,
  },
  mixins: [checkValidations, hasFormValidations],
  props: {
    identificationInformation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: IdentificationInformationForm(this.identificationInformation),
      loading: false,
      open: false,
    };
  },
  methods: {
    close() {
      this.open = false;
      this.$v.$reset();
      this.form.updateInitialValues(this.identificationInformation);
    },
    async save() {
      if (!this.isValid()) return;

      this.loading = true;
      const data = this.form.getFormValues();

      try {
        await this.$store.dispatch(
          'insuranceApplication/saveIdentificationInformation',
          data
        );
      } catch (error) {
        this.$logger.error(error);
      }

      this.loading = false;
      this.close();
    },
  },
};
</script>

<style lang="scss">
.identification-information {
}
</style>
