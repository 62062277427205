<template>
  <div class="general-information">
    <v-row class="info-block align-center mb-4" dense>
      <v-col cols="auto" class="mr-4">
        <span class="s1-subtitle aon-peacock--text">
          {{ $t(`mappings.insurance.${currentInsuranceType}`) }}
        </span>
        <br />
        <span class="p2-paragraph aon-gray-02--text">
          {{ submitted }}
        </span>
      </v-col>
      <v-col cols="auto">
        <v-chip class="status" :class="chipClass">
          {{ $t(`coverageRequest.status.${application.status}`) }}
        </v-chip>
      </v-col>
    </v-row>

    <InfoColumnsCard
      :title="$t('coverageRequest.generalInformation.requestedCoverage')"
      :columns="requestedCoverageColumns"
    />
    <InfoColumnsCard
      :title="$t('identificationInformation.title')"
      :columns="identificationInfoColumns"
    >
      <template v-if="!disabled && !applicationCompleted" #actions>
        <IdentificationInformationDialog
          :identification-information="identificationInformation"
        />
      </template>
    </InfoColumnsCard>
  </div>
</template>

<script>
import { getDateFromUTC } from '@aon/cfs-utils';
import InfoColumnsCard from '@aon/cfs-components/src/components/InfoColumnsCard';
import { getStatusChipClass } from '@/lib/helpers';
import currencyFilter from '../../filters/currency';
import { mapState } from 'vuex';
import IdentificationInformationDialog from '@/components/CoverageRequests/IdentificationInformationDialog';

export default {
  name: 'GeneralInformation',
  components: {
    IdentificationInformationDialog,
    InfoColumnsCard,
  },
  props: {
    applicationCompleted: {
      type: Boolean,
      Required: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editIdentificationInfo: false,
    };
  },
  computed: {
    ...mapState('insuranceApplication', {
      currentInsuranceType: state => state.insuranceType,
      application: state => state.application,
    }),
    identificationInformation() {
      return this.application.identificationInformation;
    },
    chipClass() {
      return getStatusChipClass(this.application.status);
    },
    submitted() {
      return this.$t('coverageRequest.generalInformation.submitted', {
        date: getDateFromUTC(this.application.submitted),
      });
    },
    requestedCoverageColumns() {
      const text = (key, data) =>
        this.$t(`coverageRequest.generalInformation.${key}`, data);
      const requestedCoverage = this.application.requestedCoverage;

      return [
        {
          label: text('coverage'),
          value: requestedCoverage.coverageAmount
            ? currencyFilter(requestedCoverage.coverageAmount)
            : '--',
        },
        {
          label: text('perOccurrenceCoverage'),
          value: requestedCoverage.perOccurrenceCoverageAmount
            ? currencyFilter(requestedCoverage.perOccurrenceCoverageAmount)
            : '--',
        },
        {
          label: text('deductible'),
          value: currencyFilter(requestedCoverage.deductibleAmount),
        },
        {
          label: text('territory'),
          value: this.$tc(
            `countries.countries.${requestedCoverage.territory}.name`
          ),
        },
        {
          label: text('policyType'),
          value: this.$tc(
            `coverageRequest.generalInformation.policyTypeOptions.${requestedCoverage.policyType}`
          ),
        },
        {
          label: text('effectiveDate'),
          value: getDateFromUTC(requestedCoverage.coverageStartDate),
        },
        {
          label: text('expirationDate'),
          value: getDateFromUTC(requestedCoverage.coverageExpirationDate),
        },
        {
          label: text('extendedReporting'),
          value: this.$tc(
            'coverageRequest.generalInformation.extendedReportingPeriod',
            requestedCoverage.extendedReportingPeriod
          ),
        },
      ];
    },
    identificationInfoColumns() {
      const text = (key, data) =>
        this.$t(`identificationInformation.${key}`, data);

      return [
        {
          label: text('agencyCustomerId'),
          value: this.identificationInformation.agencyCustomerId,
        },
        {
          label: text('agency'),
          value: this.identificationInformation.agency,
        },
        {
          label: text('policyNumber'),
          value: this.identificationInformation.policyNumber,
        },
        {
          label: text('naicCode'),
          value: this.identificationInformation.naicCode,
        },
      ];
    },
  },
  methods: {},
};
</script>
