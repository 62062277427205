<template>
  <SubmittedApplication
    class="submitted-application"
    :application="application"
    :insurance-type="insuranceType"
    :tenant-id="tenantId"
  >
    <template #applicationHeader>
      <div
        class="py-4 px-6 aon-peacock white--text d-flex align-center justify-space-between"
      >
        <div>
          <span class="p d-block">
            {{ $t(`mappings.applications.${insuranceType}`) }}
          </span>
          <span class="p small">
            {{ lastModified }}
          </span>
        </div>
        <div class="d-flex">
          <ApplicationComments
            :application-id="application.id"
            :tenant-id="tenantId"
            :permissions="['resolve']"
            :comment-btn-label="$t('coverageRequest.application.comments')"
            :reply-label="$t('coverageRequest.application.sendComment')"
            :enable-new-comments="canComment"
          />
          <div v-if="!completed">
            <AppButton
              v-if="showActions"
              :loading="loading"
              class="mr-2"
              @click="requestChanges"
            >
              {{ $t('coverageRequest.application.requestChanges') }}
            </AppButton>
            <AppButton v-if="showActions" :loading="loading" @click="approve">
              {{ $t('coverageRequest.application.approve') }}
            </AppButton>
          </div>
        </div>
      </div>
    </template>
  </SubmittedApplication>
</template>

<script>
import INSURANCE_APPLICATION_STATUSES from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';
import SubmittedApplication from '@aon/cfs-insurance/src/components/SubmittedApplication';
import ApplicationComments from '@aon/cfs-insurance/src/components/Commenting/ApplicationComments';
import { getDateFromUTC } from '@aon/cfs-utils';
import canComment from '@aon/cfs-insurance/src/lib/can-comment';

export default {
  name: 'Application',
  components: { SubmittedApplication, ApplicationComments },
  props: {
    application: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    insuranceType: {
      type: String,
      required: true,
    },
    tenantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    lastModified() {
      return this.$t('coverageRequest.application.lastModified', {
        date: getDateFromUTC(this.application.updated),
      });
    },
    showActions() {
      return (
        this.application.status === INSURANCE_APPLICATION_STATUSES.SUBMITTED
      );
    },
    canComment() {
      return canComment(this.application.status);
    },
  },
  methods: {
    async approve() {
      this.loading = true;

      try {
        await this.$store.dispatch(
          'insuranceApplication/updateStatus',
          INSURANCE_APPLICATION_STATUSES.APPROVED
        );
        this.$AppEventBus.$emit('snacktime', {
          type: 'success',
          message: this.$t('coverageRequest.application.approveSuccess'),
        });
      } catch (error) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('coverageRequest.application.approveError'),
        });
      }

      this.loading = false;
    },
    async requestChanges() {
      this.loading = true;
      const alert = {
        type: 'success',
        message: this.$t('coverageRequest.application.requestInfoSuccess'),
      };
      try {
        await this.$store.dispatch(
          'insuranceApplication/updateStatus',
          INSURANCE_APPLICATION_STATUSES.REQUEST_INFO
        );
      } catch (error) {
        alert.type = 'error';
        alert.message = this.$t('coverageRequest.application.requestInfoError');
      }

      this.$AppEventBus.$emit('snacktime', alert);
      this.loading = false;
    },
  },
};
</script>
