<template>
  <div class="coverage-request">
    <v-row>
      <v-col>
        <AppButton
          prepend-icon="fal fa-chevron-left"
          variation="ghost"
          :to="{ name: 'Coverage Requests' }"
        >
          {{ $t('coverageRequest.generalInformation.coverageRequests') }}
        </AppButton>
      </v-col>
    </v-row>
    <Spinner v-if="loading" class="mt-4 mx-auto" />
    <template v-else>
      <general-information
        :application-completed="applicationCompleted"
        :disabled="!canEdit"
      />
      <v-tabs
        v-model="selectedTab"
        background-color="transparent"
        slider-size="4"
      >
        <v-tab
          v-for="component in tabComponents"
          :key="'selectedTab_' + component"
        >
          {{ $t(`coverageRequest.tabs.${component.toLowerCase()}`) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items
        :key="`tab_${selectedTab}`"
        v-model="selectedTab"
        class="aon-gray-08"
      >
        <v-tab-item>
          <Application
            :application="application"
            :completed="applicationCompleted"
            :insurance-type="currentInsuranceType"
            :tenant-id="$route.params.tenantId"
          />
        </v-tab-item>
        <v-tab-item>
          <Quotes
            :application="application"
            :insurance-type="currentInsuranceType"
            :disabled="!canEdit"
          />
        </v-tab-item>
        <v-tab-item>
          <Subjectivities :application-completed="applicationCompleted" />
        </v-tab-item>
        <v-tab-item>
          <Proposal :disabled="!canEdit" />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </div>
</template>

<script>
import Application from '../components/CoverageRequests/Application';
import GeneralInformation from '../components/CoverageRequests/GeneralInformation';
import INSURANCE_APPLICATION_STATUSES from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';
import Quotes from '../components/CoverageRequests/Quotes/Quotes';
import Proposal from '../components/CoverageRequests/Proposal';
import Subjectivities from '../components/CoverageRequests/Subjectivities';
import { mapState } from 'vuex';

export default {
  name: 'CoverageRequest',
  components: {
    GeneralInformation,
    Application,
    Quotes,
    Subjectivities,
    Proposal,
  },
  data() {
    return {
      selectedTab: 0,
      loading: false,
      error: false,
      tabComponents: ['Application', 'Quotes', 'Subjectivities', 'Proposal'],
    };
  },
  computed: {
    ...mapState('insuranceApplication', {
      currentInsuranceType: state => state.insuranceType,
      application: state => state.application,
    }),
    applicationCompleted() {
      return (
        this.application.status === INSURANCE_APPLICATION_STATUSES.COMPLETED
      );
    },
    canEdit() {
      return (
        this.application.status !== INSURANCE_APPLICATION_STATUSES.WITHDRAWN
      );
    },
  },
  created() {
    this.getApplication();
  },
  methods: {
    async getApplication() {
      this.loading = true;
      this.error = false;
      const payload = {
        applicationId: this.$route.params.id,
        tenantId: this.$route.params.tenantId,
        insuranceType: this.$route.params.type,
      };

      try {
        await this.$store.dispatch(
          'insuranceApplication/getApplicationAndIdentificationInfo',
          payload
        );
      } catch (error) {
        this.$logger.error(error);
        this.error = true;
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.coverage-request {
  .v-tab {
    font-size: 14px !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
</style>
