<template>
  <v-card>
    <v-card-title class="aon-peacock--text">
      <h4 class="mb-0">{{ $t('markets.title') }}</h4>
      <v-spacer></v-spacer>
      <AppButton variation="icon" @click="closeDialog">
        <i class="fal fa-times" />
      </AppButton>
    </v-card-title>
    <v-card-text>
      <v-expansion-panels v-if="!loading" flat accordion multiple>
        <v-expansion-panel
          v-for="(market, i) in items"
          :key="'market' + i"
          class="my-1"
        >
          <v-container fluid>
            <v-row>
              <v-col class="py-0" cols="1">
                <v-checkbox
                  v-model="marketsSelected"
                  class="mx-2 aon-teal--text"
                  color="aon-teal"
                  :label="market.name"
                  :value="market.id"
                  :disabled="market.alreadySelected || disabled"
                ></v-checkbox>
              </v-col>
              <v-col class="py-0">
                <v-expansion-panel-header class="pa-0">
                  <template #actions>
                    <v-icon color="aon-teal" x-small>fal fa-chevron-up</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container class="pa-0" fluid>
                    <v-radio-group v-model="market.contactSelected">
                      <v-row
                        v-for="(contact, j) in market.marketContacts"
                        :key="'contact' + j"
                      >
                        <v-col class="py-1" cols="1">
                          <v-radio
                            :value="contact.id"
                            :disabled="market.alreadySelected || disabled"
                            color="aon-teal"
                          ></v-radio>
                        </v-col>
                        <v-col class="pt-0">
                          <div class="subtitle-1">
                            {{ contact.firstName }} {{ contact.lastName }}
                          </div>
                          <div class="caption">{{ contact.email }}</div>
                          <div class="caption">{{ contact.addressLine1 }}</div>
                          <div class="caption">
                            {{ contact.locality }}, {{ contact.region }}
                            {{ contact.postalCode }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-container>
                </v-expansion-panel-content>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel>
      </v-expansion-panels>
      <Spinner v-else class="ma-auto" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <AppButton class="my-2" variation="ghost" @click="closeDialog">{{
        $t('markets.cancel')
      }}</AppButton>
      <AppButton
        class="ma-2"
        :disabled="saveDisabled"
        :loading="saving"
        @click="saveMarkets"
      >
        {{ $t('markets.save') }}
      </AppButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MarketSelection',
  props: {
    selected: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      marketsSelected: this.selected.map(x => x.marketId),
      loading: false,
      saving: false,
    };
  },
  computed: {
    ...mapState('markets', {
      markets: state => state.markets,
    }),
    items() {
      return this.markets
        ? this.markets.map(x => {
            let alreadySelected = this.selected.find(y => y.marketId === x.id);
            return {
              id: x.id,
              name: x.name,
              marketContacts: x.marketContacts,
              alreadySelected: !!alreadySelected,
              contactSelected: !!alreadySelected
                ? alreadySelected.marketContactId
                : x.marketContacts[0].id,
            };
          })
        : [];
    },
    newMarkets() {
      return this.items.filter(
        x => !x.alreadySelected && this.marketsSelected.find(y => y === x.id)
      );
    },
    saveDisabled() {
      return this.newMarkets.length === 0;
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch('markets/getMarkets');
    this.loading = false;
  },
  methods: {
    closeDialog() {
      this.$AppEventBus.$emit('closeMarketSelectDialog');
    },
    async saveMarkets() {
      this.saving = true;

      let markets = this.newMarkets;
      for (let i = 0; i < markets.length; i++) {
        let quote = {
          applicationId: this.$route.params.id,
          marketContactId: markets[i].contactSelected,
        };

        await this.$store.dispatch('quotes/createQuote', quote);
      }

      this.saving = false;
      this.$AppEventBus.$emit('closeMarketSelectDialog', true);
    },
  },
};
</script>
