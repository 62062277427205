<template>
  <div>
    <Alert
      v-if="!pageLoading && !(quotes.length || acceptedQuote)"
      :title="$t('proposal.quotesNeeded')"
      type="info"
      class="full-width my-6"
      persistent
    />
    <v-card class="proposal elevation-4 my-4">
      <v-card-text class="aon-gray-01--text pa-8">
        <v-dialog
          v-if="!!acceptedQuote"
          v-model="showFinalizePolicy"
          content-class="finalize-policy-dialog"
          max-width="840"
          persistent
        >
          <FinalizePolicy
            :accepted-quote-id="acceptedQuote.id"
            @closeDialog="showFinalizePolicy = false"
          />
        </v-dialog>
        <Spinner v-if="pageLoading" class="mx-auto" />
        <template v-else>
          <Spinner v-if="loading" class="fixed-center" />
          <Thread
            v-show="false"
            :application-id="applicationId"
            :tenant-id="tenantId"
            :enable-comments="false"
            type="SUBJECTIVITY"
          />
          <v-row align="center">
            <v-col>
              <h4 class="aon-peacock--text">{{ $t('proposal.title') }}</h4>
            </v-col>
            <v-col cols="auto">
              <div v-if="!!sentAt">
                <p class="extra-small font-italic aon-peacock-neutral">
                  {{ $t('proposal.sentAt', [sentAt]) }}
                </p>
                <AppButton
                  v-if="canRecallProposal"
                  variation="ghost"
                  :loading="proposalLoading"
                  @click="recallProposal"
                >
                  {{ $t('proposal.recallProposal') }}
                </AppButton>
              </div>
              <AppButton
                v-else
                :disabled="loading || !canSendProposal"
                :loading="proposalLoading"
                @click="sendProposal"
              >
                {{ $t('proposal.sendProposal') }}
              </AppButton>
            </v-col>
          </v-row>
          <v-row v-if="quoteAcceptedDate" align="center">
            <v-col>
              <p class="small mb-0">
                {{ $t('proposal.quoteAccepted', [acceptedDate]) }}
              </p>
            </v-col>
            <v-col v-if="isFinalizeStatus" cols="auto">
              <p
                v-if="respondByDate"
                class="mr-2 d-inline-block extra-small font-italic aon-peacock-neutral display-block"
              >
                {{ $t('proposal.respondBy', [respondByDate]) }}
              </p>
              <template v-if="bindingOrderDate">
                <AppButton
                  class="mr-2"
                  :disabled="loading"
                  @click="showFinalizePolicy = true"
                >
                  {{ $t('proposal.finalizePolicy') }}
                </AppButton>
                <AppButton
                  variation="ghost"
                  :disabled="loading"
                  @click="getBindingOrderResponseDate('proposal.resendRequest')"
                >
                  {{ $t('proposal.resendRequest') }}
                </AppButton>
              </template>
              <template v-else>
                <AppButton
                  class="mb-2"
                  variation="ghost"
                  :disabled="loading"
                  @click="
                    getBindingOrderResponseDate('proposal.bindingRequest')
                  "
                >
                  {{ $t('proposal.bindingRequest') }}
                </AppButton>
              </template>
              <ResponseDateDialog
                :show="showRequestBindingOrderDialog"
                :title="requestBindingOrderTitle"
                @responseDateDialogClosed="
                  showRequestBindingOrderDialog = false
                "
                @responseDateSelected="requestBindingOrder"
              >
              </ResponseDateDialog>
            </v-col>
          </v-row>
          <v-row v-if="!!acceptedQuote" class="quote-row accepted">
            <v-col cols="auto">
              <v-icon style="font-size:14px" color="green">
                {{ acceptedQuote.recommended ? 'fas' : 'fal' }} fa-star
              </v-icon>
            </v-col>
            <v-col v-for="(col, i) in columns" :key="i">
              {{ acceptedQuote[col] }}
            </v-col>
          </v-row>
          <div v-if="quotes.length" class="mb-4">
            <v-row>
              <v-col>
                <p class="aon-peacock--text mb-0">
                  {{ $t('proposal.quotesTitle') }}
                </p>
              </v-col>
            </v-row>
            <v-row
              v-for="quote in quotes"
              :key="quote.id"
              class="quote-row mb-1"
              align="center"
            >
              <v-col cols="auto">
                <v-icon
                  v-if="readonly"
                  style="font-size:14px;width:36px;height:36px;"
                  :color="quote.recommended ? 'green' : ''"
                >
                  {{ quote.recommended ? 'fas' : 'fal' }} fa-star
                </v-icon>
                <AppButton
                  v-else
                  variation="icon"
                  @click="recommendQuote(quote)"
                >
                  <i
                    :class="
                      `${
                        quote.recommended ? 'success--text fas' : 'fal'
                      } fa-star`
                    "
                  />
                </AppButton>
              </v-col>
              <v-col v-for="(col, i) in columns" :key="i">{{
                quote[col]
              }}</v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <h5 class="aon-peacock--text mb-0">
                {{ $t('proposal.policyDetailsTitle') }}
              </h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <LabeledInput :label="$t('proposal.product')" :required="null">
                <v-text-field
                  v-model="$v.fields.product.$model"
                  :error-messages="form.errors($v.fields.product)"
                  :disabled="readonly"
                  outlined
                  dense
                />
              </LabeledInput>
            </v-col>
            <v-col cols="3">
              <LabeledInput :label="$t('proposal.counsel')" :required="null">
                <v-text-field
                  v-model="$v.fields.counselChoice.$model"
                  :error-messages="form.errors($v.fields.counselChoice)"
                  :disabled="readonly"
                  outlined
                  dense
                />
              </LabeledInput>
            </v-col>
            <v-col cols="3">
              <LabeledInput :label="$t('proposal.hammer')" :required="null">
                <v-text-field
                  v-model="$v.fields.hammerClause.$model"
                  :error-messages="form.errors($v.fields.hammerClause)"
                  :disabled="readonly"
                  outlined
                  dense
                />
              </LabeledInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <LabeledInput :label="$t('proposal.retention')" :required="null">
                <v-text-field
                  v-model="$v.fields.settlementRetention.$model"
                  :error-messages="form.errors($v.fields.settlementRetention)"
                  :disabled="readonly"
                  outlined
                  dense
                />
              </LabeledInput>
            </v-col>
            <v-col cols="3">
              <LabeledInput :label="$t('proposal.claims')" :required="null">
                <v-text-field
                  v-model="$v.fields.claimsNotice.$model"
                  :error-messages="form.errors($v.fields.claimsNotice)"
                  :disabled="readonly"
                  outlined
                  dense
                />
              </LabeledInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <LabeledInput
                :label="$t('proposal.execSummary')"
                :required="null"
              >
                <v-textarea
                  v-model="$v.fields.executiveSummary.$model"
                  :error-messages="form.errors($v.fields.executiveSummary)"
                  :disabled="readonly"
                  outlined
                  dense
                  rows="3"
                />
              </LabeledInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="aon-peacock--text mb-0">
                {{ $t('proposal.endorsements.title') }}
              </p>
            </v-col>
          </v-row>
          <v-row
            v-for="endorsement in $v.fields.endorsements.$each.$iter"
            :key="endorsement.$model.id"
          >
            <v-col class="pb-0">
              <v-row>
                <v-col class="py-0">
                  {{ $t('proposal.endorsements.endorsement') }}
                </v-col>
                <v-col cols="auto" class="py-0">
                  <a @click="removeEndorsement(endorsement.$model)">
                    {{ $t('proposal.endorsements.remove') }}
                  </a>
                </v-col>
              </v-row>
              <v-row>
                <v-textarea
                  v-model="endorsement.endorsement.$model"
                  :error-messages="form.errors(endorsement.endorsement)"
                  outlined
                  :disabled="readonly"
                  class="mb-0"
                  rows="3"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <a :disabled="readonly" class="link-text" @click="addEndorsement">
                {{ $t('proposal.endorsements.add') }}
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="small mb-0">
                {{ $t('proposal.coverageSummaryOptions.title') }}
              </p>
            </v-col>
          </v-row>
          <v-row v-for="(row, i) in coverageSummaryOptions" :key="i">
            <v-col cols="2" class="py-0">
              <v-checkbox
                v-model="$v.fields[row[0].key].$model"
                :error-messages="form.errors($v.fields[row[0].key])"
                :disabled="readonly"
                :label="$t(`proposal.coverageSummaryOptions.${row[0].key}`)"
                color="aon-teal"
                class="mt-0"
              />
            </v-col>
            <v-col cols="4" class="py-0 pl-0">
              <v-checkbox
                v-model="$v.fields[row[1].key].$model"
                :error-messages="form.errors($v.fields[row[1].key])"
                :disabled="readonly"
                :label="$t(`proposal.coverageSummaryOptions.${row[1].key}`)"
                color="aon-teal"
                class="mt-0"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import APPLICATION_STATUSES from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import Alert from '@aon/cfs-components/src/components/Alert';
import Thread from '@aon/cfs-insurance/src/components/Commenting/Thread';
import ProposalForm, { coverageSummaryOptions } from '@/lib/forms/proposal';
import moment from 'moment';
import FinalizePolicy from './FinalizePolicy';
import ResponseDateDialog from '@/components/CoverageRequests/ResponseDateDialog';
import Snacktime from '@aon/cfs-utils/lib/Snacktime';

const currencyColumns = ['premium', 'coverageAmount', 'deductible'];
const columns = ['market', ...currencyColumns, 'extendedReportingPeriod'];

export default {
  name: 'Proposal',
  components: {
    FinalizePolicy,
    LabeledInput,
    ResponseDateDialog,
    Thread,
    Alert,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageLoading: true,
      proposalLoading: false,
      loading: false,
      columns,
      form: ProposalForm(),
      sentAt: null,
      quotes: [],
      acceptedQuote: null,
      quoteAcceptedDate: null,
      bindingOrderDate: null,
      requestBindingOrderTitle: '',
      showRequestBindingOrderDialog: false,
      requestedResponseDate: null,
      showFinalizePolicy: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('insuranceApplication', {
      application: state => state.application,
      insuranceType: state => state.insuranceType,
    }),
    ...mapGetters('applicationComments', {
      commentsResolved: 'allResolved',
    }),
    fields() {
      return this.form.fields;
    },
    $v() {
      return this.form.$v;
    },
    readonly() {
      return this.disabled || !!this.sentAt || this.loading;
    },
    acceptedDate() {
      return moment(this.quoteAcceptedDate).format('MMM-DD-YYYY');
    },
    respondByDate() {
      return this.requestedResponseDate
        ? moment(this.requestedResponseDate).format('MMM-DD-YYYY')
        : null;
    },
    applicationId() {
      return this.$route.params.id;
    },
    tenantId() {
      return this.$route.params.tenantId;
    },
    proposalId() {
      return this.form.getFormValues().id;
    },
    canSendProposal() {
      return this.quotes.length > 0 && !this.$v.$anyError;
    },
    canRecallProposal() {
      return (
        this.application?.status === APPLICATION_STATUSES.APPLICANT_REVIEW &&
        !this.quoteAcceptedDate
      );
    },
    isFinalizeStatus() {
      return this.application?.status === APPLICATION_STATUSES.FINALIZE;
    },
    coverageSummaryOptions() {
      const summaryOptions = [];
      for (let i = 0; i < coverageSummaryOptions.length - 1; i += 2) {
        summaryOptions.push([
          coverageSummaryOptions[i],
          coverageSummaryOptions[i + 1],
        ]);
      }
      return summaryOptions;
    },
  },
  async created() {
    this.pageLoading = true;
    await this.getQuotesAndProposal();
    this.pageLoading = false;
  },
  methods: {
    async getQuotesAndProposal() {
      try {
        const id = this.applicationId;
        const quotes = await this.$store.dispatch(
          'quotes/getProposalQuotes',
          id
        );
        if (quotes.length > 0) {
          this.processQuotes(quotes);
          const proposal = await this.$store.dispatch(
            'proposal/getProposal',
            id
          );
          this.processProposal(proposal);
        }
      } catch (error) {
        this.$logger.error(error);
        if (error.response.status === 404) return;
        Snacktime({
          message: this.$t('proposal.quotesProposalError'),
          type: 'error',
        });
      }
    },
    processProposal(data) {
      const { quoteAcceptedDate, sentAt, bindingOrderDate, ...form } = data;
      this.form.updateInitialValues(form);

      this.bindingOrderDate = bindingOrderDate;
      this.quoteAcceptedDate = quoteAcceptedDate;
      this.requestedResponseDate = data.requestedResponseDate;

      this.sentAt = sentAt && moment(sentAt).format('MMM-DD-YYYY');
    },
    processQuotes(quotes) {
      quotes.forEach(q => {
        currencyColumns.forEach(c => {
          q[c] = this.$options.filters.currency(q[c]);
        });
        q['extendedReportingPeriod'] += ' yrs';
      });
      this.quotes = quotes.filter(q => !q.accepted);
      this.acceptedQuote = quotes.find(q => q.accepted);
    },
    async sendProposal() {
      this.proposalLoading = true;
      const proposal = this.form.getFormValues();
      proposal.recommendedQuotes = this.quotes
        .filter(q => q.recommended)
        .map(item => item.id);
      proposal.applicationId = this.applicationId;
      proposal.userId = this.user.id;
      proposal.tenantId = this.tenantId;
      proposal.insuranceType = this.insuranceType;
      const data = await this.$store.dispatch('proposal/send', proposal);
      await this.$store.dispatch(
        'insuranceApplication/updateStatus',
        APPLICATION_STATUSES.APPLICANT_REVIEW
      );
      this.processResponse(proposal, data);
      Snacktime({ message: this.$t('proposal.proposalSent'), type: 'success' });
      this.sentAt = moment().format('MMM-DD-YYYY');
      this.proposalLoading = false;
    },
    processResponse(proposal, data) {
      const { id, endorsementIds } = data;
      proposal.id = id;

      const endorsements = proposal.endorsements;
      for (let i = 0; i < endorsementIds.length; i++) {
        endorsements[i].id = endorsementIds[i];
      }
      this.form.updateInitialValues(proposal);
    },
    async recallProposal() {
      this.proposalLoading = true;

      const id = this.proposalId;

      try {
        await this.getQuotesAndProposal();

        if (!this.canRecallProposal) {
          Snacktime({
            message: this.$t('proposal.cantRecallProposal'),
            type: 'warning',
          });
          this.getApplication();
          return;
        }

        await this.$store.dispatch('proposal/recall', id);
        await this.$store.dispatch(
          'insuranceApplication/updateStatus',
          APPLICATION_STATUSES.OUT_TO_MARKET
        );

        Snacktime({
          message: this.$t('proposal.proposalRecalled'),
          type: 'success',
        });
        this.sentAt = null;
      } catch (error) {
        this.$logger.error(error);
        Snacktime({
          message: this.$t('proposal.proposalRecalledError'),
          type: 'error',
        });
      }
      this.proposalLoading = false;
    },
    addEndorsement() {
      if (this.readonly) return;
      this.form.addEndorsement();
    },
    removeEndorsement(endorsement) {
      if (this.readonly) return;
      this.form.removeEndorsement(endorsement);
    },
    recommendQuote(quote) {
      if (this.readonly) return;
      quote.recommended = !quote.recommended;
    },
    async requestBindingOrder(responseDate) {
      this.loading = true;

      const payload = {
        companyName: this.application.businessInformation.legalName,
        proposalId: this.proposalId,
        insuranceType: this.insuranceType,
        responseDate,
      };

      await this.$store.dispatch('proposal/requestBindingOrder', payload);
      this.requestedResponseDate = responseDate;
      this.bindingOrderDate = moment.utc().format('MMM-DD-YYYY');
      this.loading = false;
    },
    getBindingOrderResponseDate(dialogTitleKey) {
      this.requestBindingOrderTitle = this.$t(dialogTitleKey);
      this.showRequestBindingOrderDialog = true;
    },
    getApplication() {
      try {
        this.$store.dispatch(
          'insuranceApplication/getApplicationAndIdentificationInfo',
          {
            applicationId: this.$route.params.id,
            tenantId: this.$route.params.tenantId,
            insuranceType: this.$route.params.type,
          }
        );
      } catch (error) {
        this.$logger.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.proposal {
  .aon-peacock-neutral {
    color: #425a70;
  }

  .quote-row {
    margin: 0 1px;
    border: 1px solid #e4e7eb;
    border-radius: $sheet-border-radius;

    &.accepted {
      border-color: $success;
      background-color: $success-light;
    }
  }

  .link-text {
    color: $aon-teal;
    text-decoration: underline;
  }
}
</style>
