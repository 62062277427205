<template>
  <v-card class="elevation-4 my-4">
    <v-card-text class="aon-gray-01--text">
      <div
        class="subjectivity py-4 px-8 mx-n4 mb-4 d-flex justify-space-between align-center"
      >
        <div>
          <p class="aon-peacock--text mb-0">
            {{ $t('coverageRequest.application.subjectivity') }}
          </p>
          <p class="small">
            {{ $t('coverageRequest.application.subjectivityText') }}
          </p>
        </div>
        <div>
          <AppButton
            :loading="gettingQuotes || sendingSubjectivities"
            :disabled="disableSendSubjectivities"
            @click="sendSubjectivities"
          >
            {{ $t('coverageRequest.application.sendSubjectivity') }}
          </AppButton>
        </div>
      </div>
      <Thread
        :application-id="application.id"
        :tenant-id="tenantId"
        :enable-new-comments="canComment"
        :permissions="permissions"
        :reply-label="$t('coverageRequest.application.requestSubjectivity')"
        type="SUBJECTIVITY"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import INSURANCE_APPLICATION_STATUSES from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';
import { mapState, mapGetters } from 'vuex';
import Thread from '@aon/cfs-insurance/src/components/Commenting/Thread';
import canComment from '@aon/cfs-insurance/src/lib/can-comment';

export default {
  name: 'Subjectivities',
  components: { Thread },
  props: {
    applicationCompleted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      permissions: ['resolve'],
      gettingQuotes: true,
      sendingSubjectivities: false,
      sendingError: false,
    };
  },
  computed: {
    ...mapState('insuranceApplication', ['tenantId', 'application']),
    ...mapState('quotes', ['quotes']),
    ...mapGetters('applicationComments', ['allResolved', 'numberOfComments']),
    disableSendSubjectivities() {
      return (
        !(this.numberOfComments && this.allResolved) ||
        this.application.status ===
          INSURANCE_APPLICATION_STATUSES.APPLICANT_REVIEW ||
        this.application.status === INSURANCE_APPLICATION_STATUSES.FINALIZE ||
        this.application.status === INSURANCE_APPLICATION_STATUSES.COMPLETED ||
        this.application.status === INSURANCE_APPLICATION_STATUSES.WITHDRAWN
      );
    },
    marketContacts() {
      return this.quotes?.map(quote => quote.marketContact.email);
    },
    canComment() {
      return canComment(this.application.status);
    },
  },
  created() {
    this.getQuotes();
  },
  methods: {
    getQuotes() {
      const applicationId = this.$route.params.id;
      if (applicationId) {
        this.gettingQuotes = true;
        this.$store.dispatch('quotes/getQuotes', applicationId).finally(() => {
          this.gettingQuotes = false;
        });
      }
    },
    async sendSubjectivities() {
      this.sendingSubjectivities = true;
      this.sendingError = false;
      try {
        await this.$store.dispatch('applicationComments/sendSubjectivities', {
          recipients: this.marketContacts,
        });
        this.$AppEventBus.$emit('snacktime', {
          type: 'success',
          message: this.$t(
            'coverageRequest.application.sendSubjectivitySuccess'
          ),
        });
      } catch {
        this.sendingError = true;
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: this.$t('coverageRequest.application.sendSubjectivityError'),
        });
      }

      this.sendingSubjectivities = false;
    },
  },
};
</script>

<style lang="scss">
.subjectivity {
  border-bottom: 1px solid $aon-gray-02;
}
</style>
