export const FINALIZED = 'FINALIZED';
export const DECLINED = 'DECLINED';
export const ACCEPTED = 'ACCEPTED';
export const PROPOSED = 'PROPOSED';
export const COMPLETED = 'COMPLETED';
export const REQUESTED = 'REQUESTED';
export const DRAFT = 'DRAFT';

const QUOTE_STATUSES = {
  FINALIZED,
  DECLINED,
  ACCEPTED,
  PROPOSED,
  COMPLETED,
  REQUESTED,
  DRAFT,
};
Object.freeze(QUOTE_STATUSES);

export default QUOTE_STATUSES;
