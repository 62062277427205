import { Model, VueForm } from '@aon/cfs-forms';
import { maxLength } from 'vuelidate/lib/validators';

const baseProp = (defaultValue = null, validations = {}) => ({
  default: defaultValue,
  validations,
});

export const coverageSummaryOptions = [
  { key: 'breachEvent', value: 'BREACH_EVENT' },
  { key: 'firstPartyCoverage', value: 'FIRST_PARTY_COVERAGE' },
  { key: 'cyberExtortion', value: 'CYBER_EXTORTION' },
  { key: 'techEO', value: 'TECH_EO' },
  { key: 'privacyLiability', value: 'PRIVACY_LIABILITY' },
  { key: 'mediaLiability', value: 'MEDIA_LIABILITY' },
  { key: 'securityLiability', value: 'SECURITY_LIABILITY' },
  { key: 'profLiability', value: 'PROF_LIABILITY' },
  { key: 'regulatoryProceedings', value: 'REGULATORY_PROCEEDINGS' },
  { key: 'pciDSS', value: 'PCI_DSS' },
];

const summaryProps = coverageSummaryOptions.reduce((props, option) => {
  props[option.key] = baseProp(false);
  return props;
}, {});

const maxLengthValidation = {
  maxLength: maxLength(255),
};

const policyDetails = {
  product: baseProp(null, maxLengthValidation),
  counselChoice: baseProp(null, maxLengthValidation),
  hammerClause: baseProp(null, maxLengthValidation),
  settlementRetention: baseProp(null, maxLengthValidation),
  claimsNotice: baseProp(null, maxLengthValidation),
  executiveSummary: baseProp(null, maxLengthValidation),
};

const proposalModel = new Model({
  ...policyDetails,
  endorsements: baseProp([], {
    $each: { endorsement: { ...maxLengthValidation } },
  }),
  ...summaryProps,
});

export default function ProposalForm(initialValues) {
  const form = VueForm({
    model: proposalModel,
    initialValues,
    onCreated: function() {
      if (this.fields.endorsements.length < 1) {
        this.addEndorsement();
      }
    },
    methods: {
      addEndorsement(endorsement = { id: Symbol(), endorsement: null }) {
        this.fields.endorsements.push(endorsement);
      },
      removeEndorsement(endorsement) {
        this.fields.endorsements = this.fields.endorsements.filter(
          e => e.id !== endorsement.id
        );
      },
      getFormValues() {
        const fields = this._getFormValues();

        fields.summaryTypes = [];
        coverageSummaryOptions.forEach(opt => {
          if (fields[opt.key]) {
            fields.summaryTypes.push(opt.value);
          }
          delete fields[opt.key];
        });

        return fields;
      },
      updateInitialValues(initialValues) {
        const { summaryTypes, ...others } = initialValues;
        summaryTypes.forEach(type => {
          const opt = coverageSummaryOptions.find(opt => opt.value === type);
          others[opt.key] = true;
        });
        this._updateInitialValues(others);
      },
    },
    data: {},
  });

  return form;
}
