import { Model, VueForm } from '@aon/cfs-forms';
import { maxFileSize, fileType } from '@aon/cfs-forms/lib/validators';
import { required, maxLength } from 'vuelidate/lib/validators';

const model = new Model({
  coiFile: {
    default: null,
    validations: { maxFileSize: maxFileSize(), fileType: fileType(), required },
  },
  binderFile: {
    default: null,
    validations: { maxFileSize: maxFileSize(), fileType: fileType(), required },
  },
  policyNumber: {
    default: null,
    validations: { maxLength: maxLength(100), required },
  },
});

export default function Form() {
  return VueForm({ model });
}
