<template>
  <v-card>
    <spinner v-if="loading" :width="20" class="absolute-center" />
    <v-card-title>
      <h4 class="aon-peacock--text">{{ $t('finalizePolicy.pageTitle') }}</h4>
    </v-card-title>
    <v-card-text>
      <LabeledInput
        :label="$t('finalizePolicy.insuranceCertificate')"
        class="ml-4"
        required
      />
      <v-row class="mt-1" justify="center">
        <div class="file-upload">
          <v-file-input
            ref="coiFileInput"
            v-model="$ifv.fields.coiFile.$model"
            :placeholder="$t('form.uploadFile')"
            :error-messages="insuranceFileUpload.errors($ifv.fields.coiFile)"
            hide-details
            solo
            flat
            filled
            background-color="aon-gray-08"
            prepend-icon=""
            prepend-inner-icon="fal fa-paperclip"
          />
        </div>
      </v-row>
      <v-row>
        <small
          v-if="insuranceFileUpload.errors($ifv.fields.coiFile)"
          class="error--text ml-9"
        >
          {{ insuranceFileUpload.errors($ifv.fields.coiFile)[0] }}
        </small>
      </v-row>
      <LabeledInput
        :label="$t('finalizePolicy.binder')"
        class="mt-6 ml-4"
        required
      />
      <v-row class="mt-1" justify="center">
        <div class="file-upload">
          <v-file-input
            ref="binderFileInput"
            v-model="$ifv.fields.binderFile.$model"
            :placeholder="$t('form.uploadFile')"
            :error-messages="insuranceFileUpload.errors($ifv.fields.binderFile)"
            hide-details
            solo
            flat
            filled
            background-color="aon-gray-08"
            prepend-icon=""
            prepend-inner-icon="fal fa-paperclip"
          />
        </div>
      </v-row>
      <v-row
        ><small
          v-if="insuranceFileUpload.errors($ifv.fields.binderFile)"
          class="error--text ml-9"
        >
          {{ insuranceFileUpload.errors($ifv.fields.binderFile)[0] }}
        </small>
      </v-row>
      <v-divider class="my-4"></v-divider>
      <v-row dense class="mt-4">
        <v-col cols="12">
          <LabeledInput
            :label="$t('finalizePolicy.policy.policyNumber')"
            required
          >
            <v-text-field
              v-model="$ifv.fields.policyNumber.$model"
              outlined
              dense
              :error-messages="
                insuranceFileUpload.errors($ifv.fields.policyNumber)
              "
            >
            </v-text-field>
          </LabeledInput>
        </v-col>
      </v-row>
      <v-row>
        <span class="aon-peacock--text ml-3">
          {{ $t('finalizePolicy.reviewInformation') }}
        </span>
      </v-row>
      <v-row>
        <v-col cols="12">
          <LabeledInput
            :label="$t('finalizePolicy.policy.carrierCompany')"
            :required="null"
          >
            <v-text-field
              v-model="acceptedQuote.market"
              outlined
              dense
              disabled
            >
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="4" sm="12">
          <LabeledInput
            :label="$t('finalizePolicy.policy.premium')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.premium.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="4" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.coverageAmount')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.coverageAmount.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="4" sm="12">
          <LabeledInput
            :label="$t('finalizePolicy.policy.deductibleAmount')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.deductible.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('finalizePolicy.policy.startDate')"
            :required="null"
          >
            <v-text-field
              v-model="effectiveDate"
              outlined
              append-icon="fal fa-calendar"
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <LabeledInput
            :label="$t('finalizePolicy.policy.expirationDate')"
            :required="null"
          >
            <v-text-field
              v-model="coverageExpiration"
              outlined
              append-icon="fal fa-calendar"
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="4" md="6" sm="12">
          <labeled-input
            :label="$t('quotes.quote.extendedReportingPeriod')"
            :required="null"
          >
            <v-text-field
              v-model="$qv.fields.extendedReportingPeriod.$model"
              outlined
              append-icon="fa-chevron-down"
              disabled
              dense
            />
          </labeled-input>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.admitted')" :required="null">
            <v-text-field
              v-model="$qv.fields.admitted.$model"
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.breachEventExpensesLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency($qv.fields.breachEventExpensesLimit.$model)
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.aggregateLimit')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.aggregateLimit.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.businessInterruptionLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency($qv.fields.businessInterruptionLimit.$model)
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.retention')" :required="null">
            <v-text-field
              v-model="$qv.fields.retention.$model"
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.businessInterruptionHourlyWaitingPeriod')"
            :required="null"
          >
            <v-text-field
              v-model="
                $qv.fields.businessInterruptionHourlyWaitingPeriod.$model
              "
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.brokerageFee')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.brokerageFee.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.dependentBusinessInterruptionLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency(
                  $qv.fields.dependentBusinessInterruptionLimit.$model
                )
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.surplusLinesTaxesFees')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.surplusLinesTaxesFees.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="
              $t(
                'quotes.quote.dependentBusinessInterruptionHourlyWaitingPeriod'
              )
            "
            :required="null"
          >
            <v-text-field
              v-model="
                $qv.fields.dependentBusinessInterruptionHourlyWaitingPeriod
                  .$model
              "
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.totalQuoteCost')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.totalQuoteCost.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.systemFailureBusinessInterruptionLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency(
                  $qv.fields.systemFailureBusinessInterruptionLimit.$model
                )
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.technologyEao')"
            :required="null"
          >
            <v-text-field
              v-model="$qv.fields.technologyEao.$model"
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="
              $t(
                'quotes.quote.systemFailureBusinessInterruptionHourlyWaitingPeriod'
              )
            "
            :required="null"
          >
            <v-text-field
              v-model="
                $qv.fields.systemFailureBusinessInterruptionHourlyWaitingPeriod
                  .$model
              "
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.miscellaneousProfessionalLiability')"
            :required="null"
          >
            <v-text-field
              v-model="$qv.fields.miscellaneousProfessionalLiability.$model"
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.dependentSystemFailureLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency($qv.fields.dependentSystemFailureLimit.$model)
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.mediaLiabilityCoverageLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency($qv.fields.mediaLiabilityCoverageLimit.$model)
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="
              $t('quotes.quote.dependentSystemFailureHourlyWaitingPeriod')
            "
            :required="null"
          >
            <v-text-field
              v-model="
                $qv.fields.dependentSystemFailureHourlyWaitingPeriod.$model
              "
              outlined
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.securityLiabilityLimit')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.securityLiabilityLimit.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.digitalAssetProtectionLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency($qv.fields.digitalAssetProtectionLimit.$model)
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.privacyLiabilityLimit')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.privacyLiabilityLimit.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.cyberExtortionLimit')"
            :required="null"
          >
            <v-text-field
              :value="formatCurrency($qv.fields.cyberExtortionLimit.$model)"
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.regulatoryProceedingsLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency($qv.fields.regulatoryProceedingsLimit.$model)
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput :label="$t('quotes.quote.commission')" :required="null">
            <v-text-field
              v-model="$qv.fields.commission.$model"
              outlined
              dense
              disabled
            >
              <template #append>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-percent
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.paymentCardDataSecurityStandardsLimit')"
            :required="null"
          >
            <v-text-field
              :value="
                formatCurrency(
                  $qv.fields.paymentCardDataSecurityStandardsLimit.$model
                )
              "
              outlined
              dense
              disabled
            >
              <template #prepend-inner>
                <v-icon class="text-field-icon" color="aon-gray-02" small>
                  fal fa-dollar-sign
                </v-icon>
              </template>
            </v-text-field>
          </LabeledInput>
        </v-col>
        <v-col lg="6" md="6" sm="12">
          <LabeledInput
            :label="$t('quotes.quote.retroactiveDate')"
            :required="null"
          >
            <v-text-field
              v-model="retroactiveDate"
              outlined
              append-icon="fal fa-calendar"
              dense
              disabled
            />
          </LabeledInput>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <AppButton variation="ghost" :disabled="loading" @click="closeDialog()">
          {{ $t('finalizePolicy.cancel') }}
        </AppButton>
        <AppButton class="ml-2" :loading="loading" @click="finalizePolicy()">
          {{ $t('finalizePolicy.finalizeAndBind') }}
        </AppButton>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';
import INSURANCE_APPLICATION_STATUSES from '@aon/cfs-utils/lib/constants/insuranceApplicationStatuses';
import FinalizeUpload from '@/lib/forms/finalize-upload';
import QuoteForm from '@/lib/forms/quote';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import * as QuotesApi from '@/api/quotes';
import { mapState } from 'vuex';
import * as PoliciesApi from '@/api/insurancePolicies';
import currencyFilter from '../../filters/currency';

export default {
  name: 'FinalizePolicy',
  components: {
    LabeledInput,
  },
  mixins: [scrollToFirstError],
  props: {
    acceptedQuoteId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      insuranceFileUpload: FinalizeUpload(),
      acceptedQuote: {},
    };
  },
  computed: {
    ...mapState('insuranceApplication', {
      application: state => state.application,
      insuranceType: state => state.insuranceType,
    }),
    $ifv() {
      return this.insuranceFileUpload.$v;
    },
    $qv() {
      return this.form.$v;
    },
    form() {
      return QuoteForm(this.acceptedQuote);
    },
    tenantId() {
      return this.$route.params.tenantId;
    },
    retroactiveDate: {
      get() {
        return this.$qv.fields.retroactiveDate.$model
          ? this.$options.filters.longDate(
              this.$qv.fields.retroactiveDate.$model
            )
          : null;
      },
      set() {},
    },
    effectiveDate: {
      get() {
        return this.$qv.fields.coverageEffective.$model
          ? this.$options.filters.longDate(
              this.$qv.fields.coverageEffective.$model
            )
          : null;
      },
      set() {},
    },
    coverageExpiration: {
      get() {
        return this.$qv.fields.coverageExpiration.$model
          ? this.$options.filters.longDate(
              this.$qv.fields.coverageExpiration.$model
            )
          : null;
      },
      set() {},
    },
  },
  created() {
    this.getQuote();
  },
  methods: {
    async getQuote() {
      this.loading = true;

      try {
        const { data } = await QuotesApi.getQuote(this.acceptedQuoteId);
        this.acceptedQuote = data;
      } catch (error) {
        this.$logger.error(error);
        this.closeDialog();
        this.$AppEventBus.$emit('snacktime', {
          message: this.$t('finalizePolicy.loadError'),
        });
      }

      this.loading = false;
    },
    closeDialog() {
      this.insuranceFileUpload.fields.coiFile = null;
      this.insuranceFileUpload.fields.binderFile = null;
      this.$emit('closeDialog');
    },
    createPolicyObject() {
      return {
        vendorId: this.application.vendorId,
        coverageRequestId: this.acceptedQuote.applicationId,
        policyNumber: this.$ifv.fields.policyNumber.$model,
        policyHolder: this.application.applicantName,
        insuranceType: this.insuranceType,
        carrier: this.acceptedQuote.market,
        coverage: this.acceptedQuote.coverageAmount,
        perOccurrenceCoverage: this.acceptedQuote.perOccurrenceCoverageAmount,
        deductible: this.acceptedQuote.deductible,
        territory: this.acceptedQuote.territory,
        policyType: this.acceptedQuote.policyType,
        premium: this.acceptedQuote.premium,
        extendedReportingPeriod: this.acceptedQuote.extendedReportingPeriod,
        effectiveDate: this.acceptedQuote.coverageEffective,
        expirationDate: this.acceptedQuote.coverageExpiration,
      };
    },
    createFinalizePayload() {
      return {
        applicationId: this.acceptedQuote.applicationId,
        coverageRequestId: this.acceptedQuote.applicationId,
        tenantId: this.tenantId,
        quoteId: this.acceptedQuote.id,
        vendorId: this.application.vendorId,
        coiFile: this.insuranceFileUpload.fields.coiFile,
        binderFile: this.insuranceFileUpload.fields.binderFile,
        newPolicy: this.createPolicyObject(),
      };
    },
    canFinalize() {
      return (
        !this.$ifv.$anyError &&
        this.insuranceFileUpload.fields.coiFile !== null &&
        this.insuranceFileUpload.fields.binderFile !== null &&
        this.application !== null
      );
    },
    async finalizePolicy() {
      this.$ifv.$touch();
      if (!this.canFinalize()) {
        this.scrollToFirstError();
        return;
      }
      const payload = this.createFinalizePayload();

      this.loading = true;
      try {
        // await PoliciesApi.finalizeQuote(payload.quoteId);
        const policyId = await PoliciesApi.finalizePolicy(payload);

        await PoliciesApi.uploadDocument({
          file: payload.coiFile,
          type: 'COI',
          correlationId: policyId.data,
          tenantId: payload.tenantId,
        });
        await PoliciesApi.uploadDocument({
          file: payload.binderFile,
          type: 'BINDER',
          correlationId: policyId.data,
          tenantId: payload.tenantId,
        });
        await this.$store.dispatch(
          'insuranceApplication/updateStatus',
          INSURANCE_APPLICATION_STATUSES.COMPLETED
        );
        this.closeDialog();
        this.$AppEventBus.$emit('snacktime', {
          message: this.$t('finalizePolicy.finalizeSuccess'),
        });
      } catch (e) {
        this.$logger.error(e);
        this.$AppEventBus.$emit('snacktime', {
          message: this.$t('finalizePolicy.finalizeError'),
          color: 'error',
        });
      }
      this.loading = false;
    },
    formatCurrency(value) {
      return currencyFilter(value);
    },
  },
};
</script>

<style lang="scss">
.finalize-policy-dialog {
  .v-card {
    font-weight: normal;
    color: black !important;
  }

  .theme--light.v-input--is-disabled input {
    color: black;
  }

  div.file-upload {
    min-width: 750px;
    background-color: $aon-gray-08;
    border: 2px dashed $aon-gray-08;

    .v-icon {
      font-size: 20px;
      margin-left: 10px;
    }

    .v-file-input__text--placeholder {
      color: $aon-teal;
      font-weight: 500;
    }

    .v-text-field__slot {
      margin-left: 10px;
      color: $aon-teal;
      font-weight: 500;
    }
  }
}
</style>
