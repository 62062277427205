import { Model, VueForm } from '@aon/cfs-forms';
import { maxLength } from 'vuelidate/lib/validators';

const model = new Model({
  agencyCustomerId: {
    default: null,
    validations: { maxLength: maxLength(50) },
  },
  agency: {
    default: null,
    validations: { maxLength: maxLength(50) },
  },
  policyNumber: {
    default: null,
    validations: { maxLength: maxLength(50) },
  },
  naicCode: {
    default: null,
    validations: { maxLength: maxLength(50) },
  },
});

export default function Form(initialValues) {
  return VueForm({
    model,
    initialValues,
    methods: {
      getFormValues() {
        return this._getFormValues();
      },
    },
  });
}
